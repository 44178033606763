// src/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="t-c-pages">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h1>Privacy Policy</h1>
          
          <h2>1. Introduction</h2>
          <p>Email Scale ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, share, and protect your personal information. By using our services, you agree to the collection and use of information in accordance with this policy.</p>
		  

          <h2>2. Governing Law</h2>
          <p>This Privacy Policy is governed by the laws of the United Kingdom. Any disputes arising from this policy will be handled in accordance with UK law.</p>
		  

          <h2>3. Information We Collect</h2>
		  
          <h4>3.1 Personal Information</h4>
          <p>We collect personal information that you voluntarily provide to us when you register for our services, make a purchase, or communicate with us. This information may include your name, email address, and payment details.</p>
          
          <h4>3.2 Usage Data</h4>
          <p>We may collect information about how our services are accessed and used. This usage data may include your IP address, browser type, browser version, pages visited, time spent on those pages, and other diagnostic data.</p>
          
          <h4>3.3 Cookies</h4>
          <p>We use cookies and similar tracking technologies to track the activity on our website and hold certain information. You can choose to disable cookies through your browser settings, though this may affect your ability to use some features of our services.</p>
		  

          <h2>4. How We Use Your Information</h2>
		  
          <h4>4.1 Service Provision</h4>
          <p>We use the information we collect to provide, maintain, and improve our services, process transactions, and communicate with you.</p>
          
          <h4>4.2 Customer Support</h4>
          <p>Your information helps us respond to your requests, questions, and concerns more effectively.</p>
          
          <h4>4.3 Marketing and Updates</h4>
          <p>With your consent, we may send you promotional materials or updates about our services. You can opt out of these communications at any time.</p>
		  

          <h2>5. Sharing Your Information</h2>
		  
          <h4>5.1 Third-Party Service Providers</h4>
          <p>We may share your information with third-party service providers who assist us in providing our services, such as payment processing, email delivery, and customer support. These providers are obligated to maintain the confidentiality of your information and use it only for the purposes specified by us.</p>
          
          <h4>5.2 Legal Requirements</h4>
          <p>We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a court or government agency).</p>
		  

          <h2>6. Data Security</h2>
          <p>We take reasonable steps to protect your personal information from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.</p>
		  

          <h2>7. Data Retention</h2>
          <p>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. Once your information is no longer required, we will take appropriate steps to delete or anonymize it.</p>
		  

          <h2>8. Your Rights</h2>
          <h4>8.1 Access</h4>
          <p>You have the right to request a copy of the personal information we hold about you.</p>
          
          <h4>8.2 Correction</h4>
          <p>If any of your information is incorrect or incomplete, you have the right to ask us to correct it.</p>
          
          <h4>8.3 Deletion</h4>
          <p>You may request the deletion of your personal information, subject to certain legal obligations.</p>
          
          <h4>8.4 Objection</h4>
          <p>You have the right to object to the processing of your personal information in certain circumstances, including for direct marketing purposes.</p>

          <h2>9. Changes to This Privacy Policy</h2>
          <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on our website, and your continued use of our services following the posting of changes constitutes your acceptance of such changes.</p>

          <h2>10. Contact Us</h2>
          <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href="mailto:support@emailscale.io">support@emailscale.io</a>.</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
