import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import { getSessionData } from './utils';
//const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;

const AddEmail = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [cName, setCName] = useState('');
  const [loading, setLoading] = useState(false);
  const [nameValid, setNameValid] = useState(true);
  const [nameError, setNameError] = useState('');
  const fileInputRef = useRef(null);

  const handleCampaignNameChange = async (e) => {
    const cname = e.target.value;
    setCName(cname);
    
    if (cname.trim() === '') {
      setNameValid(false);
      setNameError("Campaign name can't be empty.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/check-campaign-name`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ campaign_name: cname }),
      });

      const result = await response.json();

      if (response.ok && result.isUnique) {
        setNameValid(true);
        setNameError('');
      } else {
        setNameValid(false);
        setNameError('Campaign name already exists. Please choose another.');
      }
    } catch (error) {
      console.error("Error checking campaign name:", error);
      setNameValid(false);
      setNameError('Error checking campaign name. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nameValid) {
      alert("Please choose a unique campaign name.");
      return;
    }

    if (!file) {
      alert("Please upload a CSV file.");
      return;
    }

    setLoading(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
        const userId = getSessionData('user')?.id;

        if (!userId) {
          alert('User ID is missing');
          setLoading(false);
          return;
        }

        const endpoint = 'email-campaign';
        try {
          const response = await fetch(`${apiUrl}/api/${endpoint}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify({ data, user_id: userId, cName: cName }),
          });

          if (response.ok) {
            alert("CSV data uploaded successfully!");
          } else {
            const errorData = await response.json();
            if (errorData.error) {
              alert(`Error: ${errorData.error}`);
            } else {
              alert("Error uploading data.");
            }
          }
        } catch (error) {
          console.error("Error:", error);
          alert("Error uploading data.");
        } finally {
          setFile(null);
          setFileName('');
          setLoading(false);
          fileInputRef.current.value = '';
        }
      },
    });
  };

  return (
    <div className="addnew_emails_mainsec">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="csv_upload_boxsec">
            <form onSubmit={handleSubmit}>
              <div className="campaign_input_box">
                <lable>Campaign Name</lable>
                <input
                  type="text"
                  name="campaign_name"
                  className="form-control"
                  onChange={handleCampaignNameChange}
                  value={cName}
                />
                <br/>
                {!nameValid && (
                  <p style={{ color: 'red' }}>{nameError}</p>
                )}
              </div>

              <div className="upload_box">
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileChange}
                  accept=".csv"
                  ref={fileInputRef}
                />
                <div className="upload_txt">
                  <i className="fas fa-upload"></i>
                  <div className="contnt_txt">
                    <p>{fileName ? fileName : 'Choose a file or drag it here.'}</p>
                  </div>
                </div>
              </div>

              {loading && (
                <div className="loader">
                  <p>Loading...</p>
                </div>
              )}

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="btn_mainsec">
                  <button type="submit" className="btn_style" disabled={loading || !nameValid}>
                    {loading ? "Uploading..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {loading ? <div className="loader_mainsec">
          <div className="spinner"></div>
        </div> : <div></div>}
      </div>
    </div>
  );
};

export default AddEmail;
