import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { saveSessionData, getSessionData } from '../components/utils';
import { useLocation } from 'react-router-dom';
const homeUrl = process.env.REACT_APP_HOME_URL;
const user = getSessionData('user');
const logout = () => {
  saveSessionData('user', null);
  window.location.href = homeUrl+'/login';
};
const Sidebar = () => {
  const { campaignId } = useParams();
  const location = useLocation(); // Get the current location
  return (
    <div className="menu_sidebar">
      <div className="logo_box">
        <NavLink to="/dashboard"><img src="/assets/img/email-scale-logo-white.png" alt="Logo" /></NavLink>
      </div>
        {(location.pathname === '/add-email' || location.pathname === '/analytics' || location.pathname === '/campaigns' || location.pathname === `/campaign-emails/${campaignId}`) &&
      <div className="menu_box">
        <ul>
          <li><NavLink to="/add-email" activeClassName="active"><i className="far fa-envelope"></i> <span className="name_txt">Add Email</span></NavLink></li>
          <li><NavLink to="/analytics" activeClassName="active"><i className="fas fa-chart-line"></i> <span className="name_txt">Analytics</span></NavLink></li>
          <li><NavLink to="/campaigns" activeClassName="active"><i className="fas fa-bullhorn"></i> <span className="name_txt">Campaigns</span></NavLink></li>
        </ul>
      </div>
      }
      <div className="profile_box_mainsec">
        <div className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span className="profile_imgbox">
              <img src="/assets/img/profile-default-img.png" alt="Profile" />
            </span>
            <span className="name_txt">{user.name}</span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><NavLink className="dropdown-item" to="/account"><i className="fas fa-user-circle"></i> My Account</NavLink></li>
            <li><NavLink className="dropdown-item"  onClick={logout}><i className="fas fa-sign-out-alt"></i> Logout</NavLink></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
