import React from 'react';
import ReactApexChart from "react-apexcharts";

const Analytics = () => {

  const chartOptions = {
    series: [
      {
        name: "Campaigns",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Campaigns Analysis",
        align: "left",
        style: {
          fontSize: "20px",
          color: "#fff",
          fontWeight: '600',
          fontFamily: 'Work Sans,sans-serif !important'
        }
      },
      subtitle: {
        text: "Campaigns Date",
        align: "left",
        style: {
          fontSize: "12px",
          color: "#fff",
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2023-01-01",
          "2023-02-01",
          "2023-03-01",
          "2023-04-01",
          "2023-05-01",
          "2023-06-01",
          "2023-07-01",
          "2023-08-01",
          "2023-09-01",
        ],
        labels: {
          style: {
            colors: "#fff", // X-axis label color
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: true,
          color: "#fff", // X-axis border color
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#00E396", // Y-axis label color
            fontSize: "12px",
          },
        },
        opposite: false,
      },
      legend: {
        horizontalAlign: "left",
        labels: {
          colors: "#546E7A", // Legend label color
        },
      },
    },
  };

  return (
    <div>
      <div className="campaign_chart">
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default Analytics;
