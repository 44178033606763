import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils'; 

const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL; 
const user = getSessionData('user'); 
const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    // Fetch the email campaign data when the component mounts
    const fetchCampaigns = async () => {
      const endpoint = `list-email-campaigns?user_id=${user.id}`; 
      try {
        const response = await fetch(`${apiUrl}/api/${endpoint}`);
        if (!response.ok) {
          throw new Error('Network response was not ok'); 
        }
        const data = await response.json();
        setCampaigns(data); 
      } catch (error) {
        console.error('Error fetching email campaigns:', error);
      } finally {
        setLoading(false); 
      }
    };

    if (user && user.id) { 
      fetchCampaigns();
    } else {
      setLoading(false); // Turn off loading indicator
    }
  }, []); 

  const handleRunCampaign = async (id) => {
    setLoading(true);
    const endpoint = 'run_campaign';
    const response = await fetch(`${apiUrl}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    setLoading(false);
    const data1 = await response.json();
    return data1;
  };

  return (
    <div className="email_listing_mainsec">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card-body">
            <div className="table_mainsec">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Campaign Name</th>
                      <th>Total Emails</th>
                      <th>Create Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.length > 0 ? (
                      campaigns.map((campaign, index) => (
                        <tr key={index}>
                          <td>{campaign.id}</td>
                          <td>{campaign.campaign_name}</td>
                          <td>{campaign.total_emails}</td>
                          <td>{new Date(campaign.created_at).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}</td>
                          <td>
                            <div className="btn_mainsec">
                              <a className="btn_style" href={`${homeUrl}/campaign-emails/${campaign.id}`}>View</a>
                              <button className="btn_style" onClick={(e) => handleRunCampaign(campaign.id)}>Run</button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No campaigns found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loader_mainsec">
          <div className="loader_innerbox">
            <div className="spinner_box">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Campaigns;
