import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { saveSessionData, getSessionData } from './utils';
import CardForm from './CardForm';
const stripePromise = loadStripe('pk_live_51Q71mCJccVUuWpvsNAvPKvBCVh3EXn87db64eF0Dbf5cnuFaqKlEzMfavx7T771UESKpazi6ExXt9etF9uV0XLUw00cKJjwl0H');
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;

const BulkUpload = () => {
  const navigate = useNavigate();
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');
  const [tamout, setTamout] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = getSessionData('user');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(password !== cpassword) {
      alert('Confirm password mismatch');
      return false;
    }
    else {
      setTamout(99);
    }
  };

  const handlePayment = async (paymentMethodId) => {
    setLoading(true);
      const response = await fetch(apiUrl+'/api/register_payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount: tamout*100,
        paymentMethodId,
        "name": fname+' '+lname,
        fname,
        lname,
        email,
        password,
        'phone': '+1.5555555555',
        'address': '510 Townsend St',
        'postal_code': '98140',
        'city': 'San Francisco',
        'state': 'CA',
        'country': 'US'
      })
    });

    const paymentResult = await response.json();
    console.log(paymentResult);
    setLoading(false);
    if(paymentResult.status) {
      alert('Payment successful');
      window.location.href = homeUrl+'/login';
    }
    else {
      alert(paymentResult.error);
    }
  };

  const handleClosePopup = () => {
    setTamout(0);
  };

  return (
  	<div>  
        <div className="form_mainsec">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="form_box">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="form-label">First Name</label>
                      <input  
                          type="text"
                          value={fname}
                          onChange={(e) => setFname(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Name" 
                        />
                    </div>
                    
                    <div className="form-group">
                      <label className="form-label">Last Name</label>
                      <input  
                          type="text"
                          value={lname}
                          onChange={(e) => setLname(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Name" 
                        />
                    </div>
                    
                    <div className="form-group">
                      <label className="form-label">Email Address</label>
                      <input  
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Email address" 
                        />
                    </div>
                    
                    <div className="form-group">
                      <label className="form-label">Password</label>
                      <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          className="form-control" 
                          placeholder="Password"
                        />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        value={cpassword}
                        onChange={(e) => setCpassword(e.target.value)}
                        required 
                        className="form-control" 
                        placeholder="Confirm Password"
                      />
                    </div>
                    
                    <div className="btn_mainsec">
                      <button type="submit" className="btn_style">Pay Now</button>
                    </div>

                    <div className="signup_link">
                      <span>I have an account? <a href={`${homeUrl}/login`}>Login</a></span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {tamout && (
        <div>
          <div className="modal fade infopopupModal in show" id="infopopupModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{display:'block'}}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="closebtn">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}></button>
                  </div>
                  <div className="contnt_mainsec">
                    <h2>Card Details</h2>
                  </div>
                  <div className="payment_form">
                    <h4 className="amount_heading">Amount: £{tamout}</h4>
                    <Elements stripe={stripePromise}>
                      <CardForm handlePayment={handlePayment} />
                    </Elements> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}

    </div>
  );
};
export default BulkUpload;