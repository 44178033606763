import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSessionData } from './utils'; 

const apiUrl = process.env.REACT_APP_API_URL; 
const user = getSessionData('user'); 
const CampaignEmails = () => {
  const { campaignId } = useParams();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get_campaign_emails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ id: campaignId }),
        });

        const result = await response.json();

        if (response.ok) {
          setCampaigns(result.emails);
        }
      } catch (error) {
        console.error("Error checking campaign name:", error);
      } finally {
        setLoading(false); 
      }
    };

    if (user && user.id) { 
      fetchCampaigns();
    } else {
      setLoading(false); // Turn off loading indicator
    }
  }, []); 




  return (
    <div className="email_listing_mainsec">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card-body">
            <div className="table_mainsec">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Campaign Name</th>
                      <th>Email</th>
                      <th>First Name</th>
                      <th>last Name</th>
                      <th>Imap Username</th>
                      <th>Imap Password</th>
                      <th>Imap Host</th>
                      <th>Imap Port</th>
                      <th>Create Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.length > 0 ? (
                      campaigns.map((campaign, index) => (
                        <tr key={index}>
                          <td>{campaign.id}</td>
                          <td>{campaign.campaign_name}</td>
                          <td>{campaign.email}</td>
                          <td>{campaign.first_name}</td>
                          <td>{campaign.last_name}</td>
                          <td>{campaign.imap_username}</td>
                          <td>{campaign.imap_password}</td>
                          <td>{campaign.imap_host}</td>
                          <td>{campaign.imap_port}</td>
                          <td>{new Date(campaign.created_at).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10">No email found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loader_mainsec">
          <div className="loader_innerbox">
            <div className="spinner_box">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignEmails;
